import get from "lodash.get";
import { queryCache } from "react-query";
import { ROLES, STATUSES } from "../constants";
import usePermissionsContext from "./usePermissionsContext";

const getContractStatus = (contractData = {}) => {
  const { status, endorsementStatus } = contractData;
  const wouldBeReferredData = get(contractData, "wouldBeReferred", []);
  const wouldBeReferred = wouldBeReferredData.length > 0;

  if (!status) {
    return STATUSES.NEW;
  }

  if (endorsementStatus) {
    return endorsementStatus;
  }

  if (status === STATUSES.SUBMITTED && wouldBeReferred) {
    return STATUSES.WILL_BE_REFERRED;
  }

  return status;
};

export default (contractData) => {
  const permissions = usePermissionsContext();
  const userData = get(queryCache.getQueryData("user"), "attributes", {});
  const usersData = get(queryCache.getQueryData("users"), "data", []);
  const tenantsData = get(queryCache.getQueryData("tenants"), "data", []);
  const role = get(userData, "custom:role");
  const tenantId = get(userData, "custom:tenantId");
  const isBroker = role === ROLES.BROKER;
  const isClaimsHandler = role === ROLES.CLAIMS_HANDLER;
  const isUnderwriter = role === ROLES.UNDERWRITER;
  const hasFullName = userData.given_name && userData.family_name;
  const fullName = hasFullName && `${userData.given_name} ${userData.family_name}`;
  const tenant = tenantsData.find((item) => item.id === tenantId) || {};
  const product = get(tenant, "products", []).find((item) => item.canonical_name === "ukterrorism");
  const commission = parseFloat(get(product, "commission"));

  const contractTenant =
    tenantsData.find((item) => item.id === get(contractData, "tenantId")) || {};

  const contractUser = usersData.find((item) => item.username === get(contractData, "contactId"));
  const foundContractUser = contractUser || {};
  const contractUserFullName = `${foundContractUser.firstName} ${foundContractUser.lastName}`;

  const checkPermissions = (...args) => {
    if (args.length === 1) {
      const [action] = args;
      const mappedStatus = getContractStatus(contractData);

      return get(permissions, [role, action]) || get(permissions, [role, mappedStatus, action]);
    }

    if (args.length === 2) {
      const [action, status] = args;

      return get(permissions, [role, status, action]);
    }

    return false;
  };

  return {
    checkPermissions,
    contractTenant,
    contractUser: { ...contractUser, fullName: contractUser && contractUserFullName },
    isBroker,
    isClaimsHandler,
    isUnderwriter,
    role,
    tenant: { ...tenant, commission },
    tenants: tenantsData,
    user: { ...userData, fullName: hasFullName && fullName },
    users: usersData,
  };
};
