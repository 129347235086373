import findKey from "lodash.findkey";
import get from "lodash.get";
import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import * as api from "../../api";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import PageLoader from "../../components/PageLoader";
import Tabs from "../../components/Tabs";
import { ACTIONS, POLICY_STATUSES, ROUTES, STATUSES } from "../../constants";
import { useAuth, useModal } from "../../hooks";
import EditQuoteModal from "../../modals/EditQuoteModal";
import ContractDetails from "../ContractDetails";
import ContractEndorsements from "../ContractEndorsements";
import Header from "../Header";
import Timeline from "../Timeline";

const types = {
  policies: "Policy",
  quotes: "Quote",
};

const Contract = () => {
  const { contractId, contractType } = useParams();
  const history = useHistory();

  const contractQuery = useQuery(["contract", { contractId }], api.getContract);
  const contractData = get(contractQuery, "data.data", {});
  const contractRef = get(contractData, "ref");

  const refDataQuery = useQuery(["refData"], api.getRefData);
  const refData = get(refDataQuery, "data.data");

  const { isBroker, isUnderwriter, checkPermissions } = useAuth(contractData);
  const { modal, showModal, closeModal } = useModal();

  const attachmentsQuery = useQuery(["attachments", { contractId }], api.getContractAttachments);
  const attachmentsData = get(attachmentsQuery, "data.data.documents");

  const is404 = get(contractQuery, "error.response.data.message") === "Entity not found";

  const contractStatus = get(contractData, "status");
  const isReferred = contractStatus === STATUSES.REFERRED;
  const isReferredQuoted = contractStatus === STATUSES.REFERRED_QUOTED;
  const isInforce = contractStatus === STATUSES.INFORCE;
  const isBound = contractStatus === STATUSES.BOUND;
  const isCancelled = contractStatus === STATUSES.CANCELLED;

  const mightUseHistory = isReferred || isReferredQuoted || isInforce || isBound || isCancelled;
  const isPolicy = POLICY_STATUSES.includes(contractStatus);

  const contractHistQuery = useQuery(["history", { contractId }], api.getContractHistory, {
    enabled: mightUseHistory,
  });

  const contractPatchesQuery = useQuery(["patches", { contractId }], api.getContractPatches, {
    enabled: mightUseHistory,
  });

  const contractHistData = get(contractHistQuery, "data.data", {});
  const contractPatchData = get(contractPatchesQuery, "data.data", {});

  const firstReferredKey = findKey(contractHistData, { status: STATUSES.REFERRED });
  const firstReferredContractData = contractHistData[firstReferredKey];

  const hasHistory = Object.keys(contractHistData).length > 1;

  const shouldShowHistory =
    hasHistory &&
    ((isBroker && isReferredQuoted) || (isUnderwriter && (isReferred || isReferredQuoted)));

  const handleConfirmEditQuote = () =>
    history.push(compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType }));

  if (
    refDataQuery.isLoading ||
    contractQuery.isLoading ||
    attachmentsQuery.isLoading ||
    contractHistQuery.isLoading ||
    contractPatchesQuery.isLoading ||
    (mightUseHistory && (contractPatchesQuery.isIdle || contractHistQuery.isIdle))
  ) {
    return <PageLoader />;
  }

  if (is404) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <>
      {modal === "EditQuoteModal" && (
        <EditQuoteModal handleClose={closeModal} handleConfirm={handleConfirmEditQuote} />
      )}

      <Header
        attachmentsData={attachmentsData}
        contractData={contractData}
        headingText={`${types[contractType]} - ${contractRef}`}
        refData={refData}
      />

      <Tabs className="border-b border-solid border-gray-400 px-8">
        <Tabs.Tab to={compile(ROUTES.CONTRACT_DETAILS)({ contractId, contractType })}>
          Details
        </Tabs.Tab>

        {shouldShowHistory && (
          <Tabs.Tab to={compile(ROUTES.CONTRACT_HISTORY)({ contractId, contractType })}>
            History
          </Tabs.Tab>
        )}

        {checkPermissions(ACTIONS.ENDORSEMENTS) &&
          checkPermissions(ACTIONS.VIEW_ENDORSEMENTS_TAB) && (
            <Tabs.Tab to={compile(ROUTES.CONTRACT_ENDORSEMENTS)({ contractId, contractType })}>
              Endorsements
            </Tabs.Tab>
          )}
      </Tabs>

      <div className="p-8 pb-40">
        <Switch>
          <Route
            path={ROUTES.CONTRACT_DETAILS}
            render={() => (
              <ContractDetails
                contractData={isBroker && isReferred ? firstReferredContractData : contractData}
                isCurrent
                refData={refData}
              />
            )}
          />

          {shouldShowHistory && (
            <Route
              path={ROUTES.CONTRACT_HISTORY}
              render={() => (
                <Timeline
                  contractPatchData={contractPatchData}
                  contractHistData={contractHistData}
                  refData={refData}
                />
              )}
            />
          )}

          {checkPermissions(ACTIONS.ENDORSEMENTS) &&
            checkPermissions(ACTIONS.VIEW_ENDORSEMENTS_TAB) && (
              <Route
                path={ROUTES.CONTRACT_ENDORSEMENTS}
                render={() => (
                  <ContractEndorsements
                    contractData={contractData}
                    contractPatchData={contractPatchData}
                    contractHistData={contractHistData}
                    refData={refData}
                  />
                )}
              />
            )}

          <Redirect to={ROUTES.CONTRACT_DETAILS} />
        </Switch>
      </div>

      {checkPermissions(ACTIONS.EDIT_CONTRACT) && !isPolicy && (
        <Footer>
          <div className="flex">
            <Button onClick={() => showModal("EditQuoteModal")}>Edit quote</Button>
          </div>
        </Footer>
      )}
    </>
  );
};

export default Contract;
