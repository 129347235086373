export const ACTIONS = {
  ACCEPT_TECHNICAL_PRICE: "Accept technical price",
  BIND_ENDORSEMENT_PRICE: "Bind endorsement price",
  BIND_PRICE: "Bind price",
  CANCEL_POLICY: "Cancel policy",
  CHANGE_COMMISSION: "Change commission",
  CREATE_COMMERCIAL_PRICE: "Create commercial price",
  CREATE_ENDORSEMENT: "Create endorsement",
  CREATE_NEW_QUOTE: "Create new quote",
  CREATE_PRICE: "Create price",
  DECLINE_TO_QUOTE: "Decline to quote",
  DELETE_ALL_LOCATIONS: "Delete all locations",
  DELETE_LOCATIONS: "Delete locations",
  DOWNLOAD_BORDEREAU: "Download bordereau",
  EDIT_BUSINESS_TYPE: "Edit business type",
  EDIT_CLAIM_BASIS: "Edit claim basis",
  EDIT_CLAIM_FLAG: "Edit claim flag",
  EDIT_CONTRACT: "Edit contract",
  EDIT_DEDUCTIBLE: "Edit deductible",
  EDIT_ENDORSEMENT: "Edit endorsement",
  EDIT_INCEPTION_DATE: "Edit inception date",
  EDIT_INDEMNITY_PERIOD: "Edit indemnity period",
  EDIT_INSURED: "Edit insured",
  EDIT_LOCATIONS: "Edit locations",
  EDIT_POLICY_LIMIT: "Edit policy limit",
  EDIT_POLICY_TERM: "Edit policy term",
  EDIT_PREMIUM_PAID_FLAG: "Edit premium paid flag",
  EDIT_SUBLIMITS: "Edit sublimits",
  ENDORSEMENTS: "Endorsements",
  INSURE_INDIVIDUALS: "Insure individuals",
  REJECT_ENDORSEMENT_PRICE: "Reject endorsement price",
  REJECT_PRICE: "Reject price",
  UPDATE_TO_NTU: "Update to NTU",
  UPLOAD_ATTACHMENTS: "Upload attachments",
  VIEW_BUSINESS_TYPE: "View business type",
  VIEW_CLAIM_FLAG: "View claim flag",
  VIEW_COMPANY_STATUS_COLUMN: "View company status column",
  VIEW_ENDORSEMENTS_TAB: "View endorsements tab",
  VIEW_INSURED_TYPE: "View insured type",
  VIEW_OUTCODES_TAB: "View outcodes tab",
  VIEW_POLICIES_TAB: "View policies tab",
  VIEW_POLICY_DOCUMENTS_TABLE: "View policy documents table",
  VIEW_PREMIUM_PAID_FLAG: "View premium paid flag",
  VIEW_QUOTE_DOCUMENT: "View quote document",
  VIEW_QUOTED_PREMIUM: "View quoted premium",
  VIEW_QUOTES_TAB: "View quotes tab",
  VIEW_REFERRALS_TAB: "View referrals tab",
  VIEW_REJECTED_PRICES_TABLE: "View rejected prices table",
  VIEW_SUBLIMITS_PREMIUM_BREAKDOWN: "View sublimits premium breakdown",
  VIEW_SUBLIMITS: "View sublimits",
  VIEW_TECHNICAL_PRICE_COLUMN: "View technical price column",
};

export default ACTIONS;
