import get from "lodash.get";
import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { Link, Redirect, Route, useHistory, useLocation } from "react-router-dom";
import * as api from "../../api";
import Button from "../../components/Button";
import Form from "../../components/Form";
import InputSearch from "../../components/InputSearch";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import { ACTIONS, POLICY_STATUSES, ROUTES, STATUSES } from "../../constants";
import { parseParams, stringifyParams } from "../../helpers";
import { useAuth, useForm, useModal } from "../../hooks";
import DownloadBordereauModal from "../../modals/DownloadBordereauModal";
import Header from "../Header";
import { columns, emptyMap, filtersMap, headingsMap } from "./config";

const ContractIndex = () => {
  const {
    checkPermissions,
    isBroker,
    isClaimsHandler,
    isUnderwriter,
    role,
    tenants,
    users,
  } = useAuth();
  const { modal, showModal, closeModal } = useModal();
  const location = useLocation();
  const { push } = useHistory();
  const params = parseParams(location.search);
  const { searchTerm = "" } = params;
  const status = filtersMap[location.pathname];
  const { getFieldProps, onSubmit, setFormValues } = useForm({
    initialState: { searchTerm },
    onSubmit: (formValues) => {
      const searchTermTrimmed = formValues.searchTerm && formValues.searchTerm.trim();

      if (searchTermTrimmed) {
        push({ search: stringifyParams({ searchTerm: searchTermTrimmed }) });
      }
    },
  });

  const filters = {
    page: params.page ? parseInt(params.page, 10) : 1,
    perPage: params.perPage ? parseInt(params.perPage, 10) : 10,
    searchTerm,
    status,
  };

  const contractsQuery = useQuery(["contracts", filters], api.getContracts, { enabled: status });
  const contractsData = get(contractsQuery, "data.data.data", []);
  const contractsCount = get(contractsQuery, "data.data.count");

  const mappedContractsData = contractsData.map((contract) => ({
    ...contract,
    tenant: tenants.find((tenant) => tenant.id === contract.tenantId),
    contact: users.find((user) => user.username === contract.contactId),
  }));

  const isLoading = contractsQuery.isLoading || tenants.length === 0 || users.length === 0;
  const hasData = contractsCount !== 0;

  const handleRowClick = (contractData) => {
    const isPolicy = POLICY_STATUSES.includes(contractData.status);
    const isInProgress = contractData.status === STATUSES.SUBMITTED;
    const canEdit = checkPermissions(ACTIONS.EDIT_CONTRACT, contractData.status);
    const contractId = contractData.id;
    const contractType = isPolicy ? "policies" : "quotes";

    if (canEdit && isInProgress) {
      return push(compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType: "quotes" }));
    }

    return push(compile(ROUTES.CONTRACT)({ contractId, contractType }));
  };

  if (location.pathname === ROUTES.HOME) {
    if (isBroker) {
      return <Redirect to={ROUTES.QUOTES_INDEX} />;
    }

    if (isUnderwriter) {
      return <Redirect to={ROUTES.REFERRALS_INDEX} />;
    }

    if (isClaimsHandler) {
      return <Redirect to={ROUTES.POLICIES_INDEX} />;
    }
  }

  return (
    <>
      {modal === "DownloadBordereauModal" && <DownloadBordereauModal handleClose={closeModal} />}

      <Header headingText={headingsMap[location.pathname]}>
        <div className="flex">
          {checkPermissions(ACTIONS.CREATE_NEW_QUOTE) && (
            <Button as={Link} to={ROUTES.NEW_QUOTE} kind="primary" aria-label="New quote">
              New quote
            </Button>
          )}

          {checkPermissions(ACTIONS.DOWNLOAD_BORDEREAU) && (
            <Button
              kind="primary"
              aria-label="Download bordereau"
              onClick={() => showModal("DownloadBordereauModal")}
            >
              Download bordereau
            </Button>
          )}

          <Form className="ml-4" onSubmit={onSubmit}>
            <InputSearch placeholderText="Search" {...getFieldProps("searchTerm")} />
          </Form>
        </div>
      </Header>

      {searchTerm && (
        <p className="mx-8 my-4">
          <span>Searching for “{searchTerm}”. </span>
          <Link
            to={location.pathname}
            onClick={() => setFormValues({ searchTerm: "" })}
            className="text-blue-900"
          >
            Clear search.
          </Link>
        </p>
      )}

      <div className="p-8">
        {isLoading && <Loader className="mt-64 mx-auto" />}

        {!isLoading && (
          <Table
            columns={columns}
            rows={mappedContractsData}
            onRowClick={handleRowClick}
            isStriped
          />
        )}

        {!isLoading && !hasData && searchTerm && (
          <div className="text-center mt-64">
            <p className="text-2xl font-medium mb-4">
              We couldn’t find any results for “{searchTerm}”
            </p>

            {/* TODO - update text */}
            {false && <p className="mb-6">This might have happened because</p>}

            {checkPermissions(ACTIONS.CREATE_NEW_QUOTE) && (
              <Route path={ROUTES.QUOTES_INDEX}>
                <p className="mb-6">Alternatively, create a new quote using the button below</p>

                <Button as={Link} to={ROUTES.NEW_QUOTE} kind="primary">
                  New quote
                </Button>
              </Route>
            )}
          </div>
        )}

        {!isLoading && !hasData && !searchTerm && (
          <div className="text-center mt-64">
            <p className="text-2xl font-medium mb-4">
              {emptyMap[location.pathname][role].headingText}
            </p>

            <p className="mb-6">{emptyMap[location.pathname][role].descriptionText}</p>

            {checkPermissions(ACTIONS.CREATE_NEW_QUOTE) && (
              <Route path={ROUTES.QUOTES_INDEX}>
                <Button as={Link} to={ROUTES.NEW_QUOTE} kind="primary">
                  New quote
                </Button>
              </Route>
            )}
          </div>
        )}

        {!isLoading && contractsCount > 10 && (
          <div className="bg-white h-32 flex items-center justify-center">
            <Pagination
              count={contractsCount}
              perPage={filters.perPage}
              page={filters.page}
              searchTerm={searchTerm}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ContractIndex;
